import classnames from 'classnames';
import React, { CSSProperties, useState } from 'react';

export enum TabsStyle {
    default,
    default_on_white_bg,
    ghost
}

export type TabsProps = {
    className?: string;
    classNameActive?: string;
    tabs: (string | React.JSX.Element)[];
    currentIndex?: number;
    onTabItemClick?: (index: number, item: string | React.JSX.Element) => void;
    style?: TabsStyle;
};

function Tabs(props: TabsProps): React.JSX.Element {
    const { className, classNameActive, currentIndex, onTabItemClick, style } = props;

    const tabs = props.tabs.filter((tab) => !!tab);

    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(currentIndex ?? 0);

    let activeButtonStyle: string;
    let inactiveBackground: string;
    let borderStyle: string;
    switch (style) {
        case TabsStyle.default_on_white_bg:
            activeButtonStyle = classnames('bg-cyan-middle text-darkBlue', classNameActive);
            inactiveBackground = 'bg-darkBlue bg-opacity-20';
            borderStyle = 'border-white-20';
            break;
        case TabsStyle.ghost:
            activeButtonStyle = classnames('bg-white', classNameActive);
            inactiveBackground = 'bg-white bg-opacity-10';
            borderStyle = 'border-white-20';
            break;
        default:
            activeButtonStyle = classnames('bg-white', classNameActive);
            inactiveBackground = 'bg-darkBlue bg-opacity-50';
            borderStyle = 'border-white-20';
            break;
    }

    const itemWidth = 100 / tabs.length;

    const cssStyle: CSSProperties = {
        width: `${itemWidth}%`,
        left: `${itemWidth * selectedTabIndex}%`
    };

    const itemStyle: CSSProperties = {
        width: `${itemWidth}%`
    };

    return (
        <div className={classnames('relative rounded-8 overflow-hidden my-auto', className, inactiveBackground)}>
            <div
                className={classnames('absolute h-full transition-all duration-200 rounded-8', activeButtonStyle)}
                style={cssStyle}
            />

            <div className={classnames('relative h-full flex flex-row')}>
                {tabs.map((tab, index) => (
                    <div
                        key={`${index}#tabItem`}
                        className={classnames(
                            'relative h-full flex flex-row text-body-bold-14 transition-all duration-200',
                            {
                                'border-r-1 border-transparent': index < tabs.length - 1,
                                [borderStyle]: index !== selectedTabIndex && index !== selectedTabIndex - 1
                            }
                        )}
                        style={itemStyle}
                        onClick={() => {
                            setSelectedTabIndex(index);
                            if (onTabItemClick) {
                                onTabItemClick(index, tabs[index]);
                            }
                        }}>
                        <div
                            className={classnames(
                                'flex flex-auto h-full select-none cursor-pointer duration-150 transition-all',
                                'px-24 py-4  items-center justify-center',
                                classNameActive,
                                'active:opacity-60',
                                {
                                    'hover:bg-white-20': selectedTabIndex !== index,
                                    'text-darkBlue': selectedTabIndex === index
                                }
                            )}>
                            {typeof tab === 'string' ? <span className="whitespace-pre">{tab}</span> : tab}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Tabs;
